import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem('accessToken') !== null ? localStorage.getItem('accessToken') : null,
    role: localStorage.getItem('role') !== null ? localStorage.getItem('role') : null

  },
  getters: {
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem('accessToken', accessToken);
    },

    setRole(state, role) {
      state.role = role;
      localStorage.setItem('role', role);
    }
    
  },
  actions: {
  },
  modules: {
  }
})
