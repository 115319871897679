<template>
<nav>
<v-navigation-drawer
      app
      class="dark white--text"
      dark
      right
      v-model="drawer"
      mini-variant-width="5000"
    >
      <v-list class="py-0">
        <v-list-item two-line class="secondary headside">
  

          <v-list-item-content class="white--text">
            <v-list-item-title>الجواز الالكتروني</v-list-item-title>
            <v-list-item-subtitle class="dark--text"
              >- ادمن</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>


        <v-list-item
          router
          active-class="secondary"
        
          link
          to='/dashboard'
        >
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>الرئيسية</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
          router
          active-class="secondary"
        
          link
          to='/statstics'
        >
          <v-list-item-icon>
            <v-icon>mdi-state-machine</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>الاحصائيات</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
          router
          active-class="secondary"
        
          link
          to='/users'
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>المستخدمين</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
          router
          active-class="secondary"
        
          link
          to='/offices'
        >
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>المكاتب</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

     

      </v-list>

      <template v-slot:append>
        <!-- <div class="pa-2 mb-2">
          <v-btn depressed block tile color="primary" @click="logout"
            >تسجيل خروج</v-btn
          >
        </div> -->
        <v-divider class="mb-5"></v-divider>
        <div class="copywrite text-center mb-5">
          <span>Digital Logic</span>
          <v-icon class="error--text mx-2 subtitle-2">mdi-copyright</v-icon>
        </div>
      </template>
    </v-navigation-drawer>


    <v-app-bar elevation="0" height="75" app>
      <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
<!-- 
      <div
        class="logo"
        :style="right ? ' border-left: 1px solid' : 'border-right: 1px solid'"
      >
        <img
        class="rounded-pill"
          src="https://www.w3schools.com/w3images/avatar2.png"
          alt="البروفايل"
          
        />
      </div> -->
      <v-toolbar-title class="secondary--color">
        <!-- منصة تاجر -->
        <v-img src="../assets/logo.svg" alt="Vuetify Logo" max-width="200" max-height="100"></v-img>
</v-toolbar-title>
<v-spacer></v-spacer>
<v-btn
        v-if="($store.state.accessToken != null && $store.state.accessToken != '' && $store.state.accessToken != 'null')"
        class=" white--text mt-md-0 mt-3"
        @click="logout"
        color="primary"
        outlined
        
      >
        <v-icon>mdi-logout</v-icon>تسجيل الخروج
      </v-btn>
    </v-app-bar>

  </nav>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    //
    drawer: true,
  }),
  methods: {
    logout() {
      this.$store.commit("setAccessToken", null);
      this.$store.commit("setRole",null );
      localStorage.clear()
      this.$router.push("/login");
    }
  }
};
</script>

<style>
</style>