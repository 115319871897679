<template>
  <div>
    <div class="d-flex justify-space-between  flex-md-row flex-column align-md-center align-end pa-4">
      <v-img src="../assets/logo.svg" alt="Vuetify Logo" max-width="400" max-height="100"></v-img>

      <v-spacer></v-spacer>

      <v-btn
        v-if="($store.state.accessToken != null && $store.state.accessToken != '' && $store.state.accessToken != 'null')"
        class=" white--text mt-md-0 mt-3"
        @click="logout"
        color="primary"
        outlined
        
      >
        <v-icon>mdi-logout</v-icon>تسجيل الخروج
      </v-btn>
    </div>

   <v-container>
    <v-stepper class="transparent elevation-0" large v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1"></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2"></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="step > 3"></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="isOkay"></v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form v-model="step1">
            <v-row class="d-flex justify-center mt-5">
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      hide-details="auto"
                      v-model="body.fullName"
                      prepend-inner-icon="mdi-account"
                      :rules="[v => !!v || 'هذا الحقل مطلوب']"
                      label="الاسم الكامل"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      hide-details="auto"
                      v-model="body.email"
                      prepend-inner-icon="mdi-email"
                      dir="ltr"
                      label="البريد الإلكتروني"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      hide-details="auto"
                      dir="ltr"
                      v-model="body.phoneNumber"
                      v-mask="$phoneMask"
                      
                      :rules="[v => v.length > 10 || 'رقم الهاتف غير صحيح']"
                      prepend-inner-icon="mdi-phone"
                      label="رقم الهاتف"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group row v-model="body.gender">
                      <v-radio label="ذكر" value="m"></v-radio>
                      <v-radio label="أنثى" value="f"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      class="primary white--text"
                      :disabled="!step1"
                      @click="nextStep"
                      block
                      large
                    >البدأ بالاستبيان</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row class="d-flex justify-center primary--text">

            <v-col cols="12">
              <h3>1- رأيك بإطلاق الجواز الإلكتروني العراقي الجديد وتصميمه</h3>
              <v-radio-group v-model="body.electronicPassportOpinion" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col>
           
            <v-col cols="12">
              <h3>2- ماهو رأيك بنظام الحجز المسبق؟</h3>
              <v-radio-group v-model="body.preBookingSystemOpinion" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col>

            <!-- <v-col cols="12">
              <h3>1- مستوى التقييم للخدمات العامة المقدمة في الدائرة</h3>
              <v-radio-group v-model="body.evaluationLevel" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col>
        -->
            <!-- <v-col cols="12">
              <h3>3- رأيك بالتصميم الجديد للدائرة وقاعات الانتظار</h3>
              <v-radio-group v-model="body.newDesignOpinion" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col> -->

            <v-col cols="12">
              <h3>3- تقييمك لسهولة وسرعة انجاز المعاملة واستلام الجواز</h3>
              <v-radio-group v-model="body.transactionsEvaluation" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <h3>4- تقييمك لساعات العمل في الدائرة</h3>
              <v-radio-group v-model="body.workingHoursEvaluation" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <h3>5- تقييمك لسلوك الموظفين داخل الدائرة</h3>
              <v-radio-group v-model="body.employeeBehaviorEvaluation" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
        
            </v-col>

            <v-col cols="12">
              <h3>6- رأيك بتوفر المرافق الصحية ونظافتها</h3>
              <v-radio-group v-model="body.sanitaryFacilitiesOpinion" row>
                <v-radio :value="0" label="😁 ممتاز"></v-radio>
                <v-radio :value="1" label="😊 جيد"></v-radio>
                <v-radio :value="2" label="😃 مقبول"></v-radio>
                <v-radio :value="3" label="🙁 سيء"></v-radio>
              </v-radio-group>
              <div class="d-flex justify-end">
                <v-btn @click="nextStep" class="rounded-xl mt-2"  x-large color="primary">
                  <v-icon>mdi-arrow-left</v-icon>
                  التالي
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-stepper-content>
       
        <v-stepper-content step="3">
          <v-row class="d-flex justify-center primary--text">
           
            <!-- <v-col cols="12">
              <h3>14- هل قمت بحجز الموعد؟ </h3>
              <v-radio-group v-model="body.hasBookedAppointment" row>
                <v-radio :value="true" label="نعم"></v-radio>
                <v-radio :value="false" label="لا"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <h3>15- هل استلمت رسالة نصية عند قيامك بالحجز المسبق؟</h3>
              <v-radio-group v-model="body.hasReceivedTextMessage" row>
                <v-radio :value="true" label="نعم"></v-radio>
                <v-radio :value="false" label="لا"></v-radio>
              </v-radio-group>
            </v-col> -->

            <v-col cols="12">
              <h3>7- مقترحاتك لتطوير الخدمات الإلكترونية للمواطنين</h3>
              <v-textarea solo class="mt-2" hide-details v-model="body.electronicServicesSuggestions"></v-textarea>
              <div v-if="!done" class="d-flex justify-end">
                <v-btn
                  @click="submit"
                  :loading="loading"
                  :disabled="loading"
                  class="rounded-lg mt-2"
                  x-large
                  color="primary"
                >
                  <v-icon>mdi-check</v-icon>تقديم
                </v-btn>
              </div>
            </v-col>
            <v-col>
              <v-alert v-if="done" color="green white--text">تم التقديم بنجاح</v-alert>

            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
   </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      step1: false,
      step: 1,
      loading: false,
      isOkay: false,
      done : false,
      body: {
        email: "",
        fullName: "",
        phoneNumber: "",
        gender: "m",
        // مستوى التقييم للخدمات العامة المقدمة في الدائرة
        evaluationLevel: 0,
        // رأيك بالجو العام (الاستقبال، البيئة، النظافة، التكييف، التهوية، الإنارة)
        overallAtmosphereOpinion: 0,
        // رأيك بالتصميم الجديد للدائرة وقاعات الانتظار
        newDesignOpinion: 0,
        // رأيك بتوفر المرافق الصحية ونظافتها
        sanitaryFacilitiesOpinion: 0,
        // رأيك بترشيق الإجراءات والتحول الرقمي
        streamliningOpinion:0,
        // تقييمك لسهولة وسرعة انجاز المعاملة واستلام الجواز
        transactionsEvaluation: 0,
        // تقييمك لساعات العمل في الدائرة
        workingHoursEvaluation: 0,
        // تقييمك لسلوك الموظفين داخل الدائرة
        employeeBehaviorEvaluation: 0,
        // هل تقدم الدائرة الخدمات بأسلوب حضاري ومتطور (نعم أم لا)
        isCivilizedAndAdvanced: true,
        // هل تتوفر المعلومات اللازمة عن الخدمة في الموقع الإلكتروني (نعم أم لا)
        isServiceInformationAvailable: true,
        // رأيك بإطلاق الجواز الإلكتروني العراقي الجديد وتصميمه
        electronicPassportOpinion: 0,
        // مدى استجابة مديرية شؤون الجوازات للاستفسارات الواردة
        passportAffairsResponseExtent: 0,
        // مقترحاتك لتطوير الخدمات الإلكترونية للمواطنين
        electronicServicesSuggestions: "" , 
         // ماهو رأيك بنظام الحجز المسبق؟
        preBookingSystemOpinion : 0 ,
         // هل قمت بحجز الموعد؟  
         hasBookedAppointment : false ,
    // هل استلمت رسالة نصية عند قيامك بالحجز المسبق؟
         hasReceivedTextMessage : false ,
 
      }
    };
  },
  mounted() {
    if (this.$store.state.accessToken == null) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    logout() {
      this.$store.commit("setAccessToken", null);
      this.$store.commit("setRole",null );
      localStorage.clear()
      this.$router.push("/login");
    },

    nextStep() {
      this.step++;
    },
    async submit() {
      this.loading = true;
      try {
        await this.$axios.post("/admin/AddQuestionnaire", this.body);
        this.isOkay = true;
        this.done = true;
        this.$router.push({ name: "feedback-success" });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
};
</script>
<style>
.v-stepper__step__step {
  padding: 18px;
  font-size: 18px;
}
.v-stepper__header {
  box-shadow: none !important;
}
.theme--light.v-label {
  color: #000 !important;
}
</style>