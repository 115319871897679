<template>
<div class="pa-10">
    <v-card flat>

        <v-card-title class="secondary white--text">
            ادارة المستخدمين
            <v-spacer></v-spacer>
            <v-btn @click="addDialogFun()">
                اضافة مستخدم
            </v-btn>
        </v-card-title>
<v-divider></v-divider>
        
<v-card-text>

    <v-data-table
    :headers="headers"
    :loading="loadData"
    :items="desserts"
    :items-per-page="5"
   loading-text="جاري جلب البيانات"
   no-data-text="لا يوجد بيانات"
            :footer-props="{itemsPerPageText: 'العدد في كل صفحة',itemsPerPageAllText : 'الكل',pageText : 'من'}"
    class="elevation-1"
  >
  <template v-slot:[`item.userType`]="{ item }">
      <v-btn
        color="primary"
        dark
        small
        depressed
        v-if="item.userType == 2"
      >
        ادمن
      </v-btn>

      <v-btn
        color="green"
        dark
        small
        depressed
        v-if="item.userType == 0"
      >
        ضابط
      </v-btn>
    </template>


    <template v-slot:[`item.actions`]="{ item }">
           <v-btn depressed color="primary" @click="editDialog = true, selectedId = item.id,formData = JSON.parse(JSON.stringify(item))" small fab>
            <v-icon
            small
            >
            mdi-pencil
            </v-icon>

           </v-btn>
            
           <v-btn depressed class="mr-2" color="error" @click="deleteDialog = true, selectedId = item.id" small fab>
            <v-icon
            small
          
            >
            mdi-delete
            </v-icon>
           </v-btn>
        </template>
  

</v-data-table>
</v-card-text>
</v-card>

<v-dialog
      v-model="addDialog"
      width="500"
    >


      <v-card>
        <v-card-title class=" grey lighten-2">
        <span>
            اضافة مستخدم
        </span>
        </v-card-title>

        <v-card-text class="mt-3">
            <v-form
            ref="form"
    v-model="valid"
  >

  <v-row>
    <v-col cols="12">
        <v-text-field
      v-model="formData.email"
      outlined
      :rules="[v => !!v || 'هذا الحقل مطلوب']"
      label="البريد الالكتروني"
      prepend-inner-icon="mdi-email"
      hide-details="auto"
      required
    ></v-text-field>

    </v-col>

    <v-col cols="12">
        <v-text-field
      v-model="formData.password"
      outlined
      :rules="[v => !!v || 'هذا الحقل مطلوب']"
      label="كلمة المرور"
      prepend-inner-icon="mdi-lock"
      hide-details="auto"
      type="password"
      required
    ></v-text-field>

    </v-col>

    <v-col>


    <v-select
      v-model="formData.officeId"
      :items="offices"
      outlined
      item-value="id"
      hide-details="auto"
      item-text="name"
      :rules="[v => !!v || 'هذا الحقل مطلوب']"
      label="المكتب"
      required
    ></v-select>
    </v-col>
  </v-row>


  </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!valid"
            :loading="loadBtn"
            @click="addUser()"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog
          v-model="editDialog"
          width="500"
        >
    
    
          <v-card>
            <v-card-title class=" grey lighten-2">
            <span>
                تعديل المستخدم 
            </span>
            </v-card-title>
    
            <v-card-text class="mt-3">
 
    
      <v-row>
    <v-col cols="12">
        <v-text-field
      v-model="formData.email"
      outlined
      :rules="[v => !!v || 'هذا الحقل مطلوب']"
      label="البريد الالكتروني"
      prepend-inner-icon="mdi-email"
      hide-details="auto"
      required
    ></v-text-field>

    </v-col>

    <v-col cols="12">
        <v-text-field
      v-model="formData.password"
      outlined
      label="كلمة المرور"
      prepend-inner-icon="mdi-lock"
      hide-details="auto"
      type="password"
      required
    ></v-text-field>

    </v-col>

    <v-col>


    <v-select
      v-model="formData.officeId"
      :items="offices"
      outlined
      item-value="id"
      hide-details="auto"
      item-text="name"
      :rules="[v => !!v || 'هذا الحقل مطلوب']"
      label="المكتب"
      required
    ></v-select>
    </v-col>
  </v-row>
    
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-btn
                color="primary"
                :disabled="!valid"
                :loading="loadBtn"
                @click="editUser()"
              >
                تعديل
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="deleteDialog"
          width="500"
        >
    
    
          <v-card>
            <v-card-title class=" grey lighten-2">
            <span>
              حذف المكتب
            </span>
            </v-card-title>
    
            <v-card-text class="mt-5">
           هل انت متأكد من حذف المكتب؟
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-btn
                color="primary"
                :loading="loadBtn"
                @click="deleteUser()"
              >
                حذف
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

</div>



</template>


<script>


  export default {
    data () {
      return {
        loadData : true,
        addDialog : false,
        valid : false,
        loadBtn : false,
        addDialog : false,
            editDialog : false,
            deleteDialog : false,
            selectedId : null,

        formData : {
            email : null,
            officeId : null,
            password : null,
            userType : 0
        },
        headers: [  
          {
            text: 'البريد الالكتروني',
            align: 'center',
            sortable: false,
            value: 'email',
          },
          { text: 'المركز',
          align: 'center',
           value: 'office.name' },
          { 
            text: 'نوع المستخدم',
            align: 'center',
           value: 'userType'
          },

          {
                text : "العمليات",
                align : "center",
                value : "actions"
               }

        ],
        desserts: [
         
        ],
        offices : [
            {id : "","name" : ""}
        ]
      }
    },

    methods: {
       getData(){
        this.axios.get("/admin/GetAllUsers").then((res)=> {
        this.desserts = res.data;
        this.loadData = false;
        })
       },
       addDialogFun(){
        this.addDialog = true;
        this.$refs.form.reset()

       },
       addUser(){
        if(this.$refs.form.validate()){
            this.loadBtn = true;
        this.$axios.post("/admin/AddUser",this.formData).then((res)=> {
            this.addDialog = false;
            this.loadBtn = false;
            this.getData();
        }).finally(()=> {
            this.loadBtn = false;
        })
        }
      
       },

       editUser(){
            this.loadBtn = true;
        this.$axios.put("/admin/UpdateUser?userId=" + this.selectedId,this.formData).then((res)=> {
            this.editDialog = false;
            this.loadBtn = false;
            this.getData();
        }).finally(()=> {
            this.loadBtn = false;
        })
     
       },

         deleteUser(){
          this.loadBtn = true;
          this.$axios.delete("/admin/DeleteUser?id=" + this.selectedId).then((res)=> {
                this.deleteDialog = false;
                this.loadBtn = false;
                this.getData();
          }).finally(()=> {
            this.loadBtn = false;
        })
         },

       getOffices(){
        this.$axios.get("/admin/GetAllOffices").then((res)=> {
          this.offices = res.data;
        })
       }
    },

    created () {
        this.getData();
        this.getOffices()
    }
  }
</script>