import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DasboardView from '../views/dashboard/DashboardView.vue'
import UsersView from '../views/users/UsersView.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: "*",
    redirect: "/login"
  },

  {
    path: '/',
    name: 'Login',
    redirect: "/login"
  },

  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: DasboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/offices',
    name: 'offices',
    component: () => import(/* webpackChunkName: "about" */ '../views/office/OfficesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statstics',
    name: 'statstics',
    component: () => import(/* webpackChunkName: "about" */ '../views/statstics/Statstics.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const curentUser = localStorage.getItem("role");
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
   if(curentUser != null){
    if(requiresAuth && curentUser != "Admin" && to.name != "home") next("home");
    if(requiresAuth && curentUser == "Admin" && to.name == "home") next("dashboard");
   }
  console.log(requiresAuth, curentUser, to.name);
  if (requiresAuth && curentUser == null) next("login");
  else if (!requiresAuth && curentUser) next("dashboard");
  else next();
});



export default router
