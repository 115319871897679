<template>
    <div class="home mt-5">
 

        <v-card flat class="ma-5">
            <v-card-title class="secondary white--text">
              الرئيسية
                <v-spacer></v-spacer>

      <v-menu
      v-if="office != null"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="0"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formDate"
            label="التاريخ من"
            readonly
            solo
            outlined
            dense
            v-bind="attrs"
            hide-details="auto"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="formDate"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
     <div class="mx-2"></div>
      <v-menu
      v-if="office != null"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="0"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDate"
            label="التاريخ الى"
            readonly
            dense
            solo
            outlined
            v-bind="attrs"
            hide-details="auto"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="toDate"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
      <div class="mx-2"></div>

                <v-btn 
                @click="exportData()"
                v-if="office != null" :loading="loadbtn">
                    تصدير البيانات
                    <v-icon small color="primary" class="mr-2">
                        mdi-file-excel
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-chip  class="mt-5 mr-5" color="primary">
                عدد الاستبيانات الكلي ({{totalMainRecord}})
            </v-chip>
            <v-chip  class="mt-5 mr-5" color="primary">
                عدد الاستبيانات حسب المركز المحدد ({{totalRecord}})
            </v-chip>
            <v-card-text>
    
                <v-row class="align-center">
                
                    <v-col>
                        <v-select item-text="name" item-value="id" @change="(v) => setOffice(v)" v-model="office" outlined :items="offices" label="اختر المكتب">

</v-select>
                    </v-col>
                </v-row>
              

    <v-data-table
    :headers="headers"
    :loading="loadData"
    :items="desserts"
    
    :items-per-page="5"
   loading-text="جاري جلب البيانات"
   no-data-text="لا يوجد بيانات"
        :footer-props="{itemsPerPageText: 'العدد في كل صفحة',itemsPerPageAllText : 'الكل',pageText : 'من'}"
    class="elevation-1"
  >

  <!-- add slot for gender  -->
  <template v-slot:[`item.gender`]="{ item }">
      <v-btn
        color="pink"
        dark
        small
        depressed
        v-if="item.gender == 'f'"
      >
        انثى
      </v-btn>

      <v-btn
        color="blue"
        dark
        small
        depressed
        v-if="item.gender == 'm'"
      >
        رجل
      </v-btn>
    </template>


    <template v-slot:[`item.hasBookedAppointment`]="{ item }">
      <v-btn
        color="green"
        dark
        small
        depressed
        v-if="item.hasBookedAppointment"
      >
        نعم
      </v-btn>

      <v-btn
        color="error"
        dark
        small
        depressed
        v-if="!item.hasBookedAppointment"
      >
        كلا
      </v-btn>
    </template>

    <template v-slot:[`item.hasReceivedTextMessage`]="{ item }">
      <v-btn
        color="green"
        dark
        small
        depressed
        v-if="item.hasReceivedTextMessage"
      >
        نعم
      </v-btn>

      <v-btn
        color="error"
        dark
        small
        depressed
        v-if="!item.hasReceivedTextMessage"
      >
        كلا
      </v-btn>
    </template>


    <template v-slot:[`item.insertDate`]="{ item }">
      {{ moment(item.insertDate).format('L') }} {{ moment(item.insertDate).format('LT') }}
    </template>


    <template v-slot:[`item.evaluationLevel`]="{ item }">
        <v-icon class="mb-1" small color="yellow">mdi-star</v-icon>   ( {{ item.evaluationLevel }} )
    </template>

    <template v-slot:[`item.newDesignOpinion`]="{ item }">
        <v-icon class="mb-1" small color="yellow">mdi-star</v-icon>   ( {{ item.newDesignOpinion }} )
    </template>

    <template v-slot:[`item.sanitaryFacilitiesOpinion`]="{ item }">
        <v-icon class="mb-1" small color="yellow">mdi-star</v-icon>   ( {{ item.sanitaryFacilitiesOpinion }} )
    </template>

    

</v-data-table>
  
  
            </v-card-text>
        </v-card>
    
    </div>
  </template>
  
  <script>
  import Tafqeet from "@/static/tafqeet.js";
  var taf = new Tafqeet();
  taf.settings({
    decimals: 2
  });
  export default {
    name: "home",
    components: {
    },
    data() {
      return {
       loadbtn : false,
       offices : [],
       office : null,
       formDate : "",
       toDate : "",
       menu1 : false,
       menu2 : false,
       totalRecord : 0,
       totalMainRecord : 0,
        headers: [  
              {
                text: "الاسم",
                align: 'center',
                sortable: false,
                value: 'fullName',
              },
              { text: 'رقم الهاتف',
              align: 'center',
               value: 'phoneNumber' },

               {
                text : "الجنس",
                align : "center",
                value : "gender"
               },

               {
                text : "حجز موعد",
                align : "center",
                value : "hasBookedAppointment"
               },
               {
                text : "وصلت الرسالة",
                align : "center",
                value : "hasReceivedTextMessage"
               },
               {
                text:"التقييم",
                align:"center",
                value:"evaluationLevel"
               },
               {
                text : "التصميم",
                align : "center",
                value : "newDesignOpinion"
               },
               {
                text : "الصحيات",
                align : "center",
                value : "sanitaryFacilitiesOpinion"
               },

               {
                text : "التاريخ",
                align : "center",
                value : "insertDate"
               },
         
    
            ],
            desserts: [
             
            ],
      };
    },

    methods : {
        getData() {
            this.loadData = true;
            this.$axios.get("/Admin/GetAllQuestionnaire").then(res => {
              this.desserts = res.data.data;
              this.totalMainRecord = res.data.totalRecords;
              this.loadData = false;
            });
          },

          setOffice(id){
            this.loadData = true;
            this.$axios.get("/Admin/GetAllOfficeQuestionnaires?officeId="+id).then(res => {
              this.desserts = res.data.data;
              this.totalRecord = res.data.totalRecords;
              this.loadData = false;
            }).finally(() => {
              this.loadData = false;
            });

            this.$axios.get("/Admin/GetResultsByWeek/results/week?officeId="+id).then(res => {
             console.log(res.data);
            }).finally(() => {
              this.loadData = false;
            });
          },

          exportData(){
            this.loadbtn = true;
            this.$axios.get("/Admin/DownloadExelFile?officeId="+this.office + "&formDate=" + this.formDate + "&toDate=" + this.toDate,{
                responseType : "blob"
            }).then(res => {
              this.loadbtn = false;
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }).finally(() => {
              this.loadbtn = false;
            });
          },


          getOffices() {
            this.$axios.get("/Admin/GetAllOffices").then(res => {
              this.offices = res.data;
            });
          },
    },
   
    created() {
    this.getData();
    this.getOffices();
    }
  };
  </script>
  
  <style lang="scss">
  .home {
    .header .v-card {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14) !important;
      margin-top: 50px !important;
    }
    @include shape;
  }
  .tbtitle {
    padding-bottom: 10px;
    border-bottom: 2px solid $primary;
  }
  </style>