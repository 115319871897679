<template>
  <v-app class="bg">
    <app-bar  v-if="islogin == 'Admin'"/>
    <v-main>
        <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
export default {
  name: "App",
  components: {
    AppBar
  },
  computed: {
    islogin() {
      return this.$store.state.role;
    },
  },
  data: () => ({
    //
  })
};
</script>
<style>
.bg {
  background-image: url("./assets/form-2.svg") !important;
  background-size: cover;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  background: #efefef !important;
  border-radius: 12px !important;
}
* {
  font-family: 'Cairo', sans-serif;
  letter-spacing:0 !important;
}
.v-btn {
  border-radius: 12px !important;
}
</style>